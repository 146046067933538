import LogoFull from "@components/LogoFull";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";

const PageFooter = () => {
  const { t } = useTranslation("common");

  const generateSection = (name, children) => {
    return (
      <>
        <article>
          <h1 className="text-lg font-semibold md:text-xl">{t(name)}</h1>
          <ul className="mt-2 text-lg footer-ul">{children}</ul>
        </article>
      </>
    );
  };

  return (
    <>
      <footer className="mt-28 bg-neutral-100 dark:bg-neutral-800">
        <div className="c-container !py-0">
          <a
            href="https://goo.gl/maps/ChfhXCtxcjU5MYmk9"
            target="_blank"
            rel="noopener noreferrer"
            className="relative block w-full rounded shadow h-44 md:h-64 -top-20"
          >
            <Image
              src="/assets/lptex-map.jpeg"
              layout="fill"
              objectFit="cover"
              className="transition duration-500 rounded grayscale hover:filter-none"
            />
          </a>
        </div>
        <div className="grid grid-cols-1 gap-6 !py-12 pb-20 -mt-20 c-container sm:grid-cols-2 lg:grid-cols-4">
          <LogoFull />
          {generateSection(
            "footer.pages",
            <>
              <li>
                <Link href="/">
                  <a>{t("navigation.home")}</a>
                </Link>
                <Link href="/about">
                  <a>{t("navigation.about")}</a>
                </Link>
                <Link href="/projects">
                  <a>{t("navigation.projects")}</a>
                </Link>
                <Link href="/contact">
                  <a>{t("navigation.contact")}</a>
                </Link>
                <Link href="/rent">
                  <a className="first-letter:capitalize">
                    {t("navigation.rental")}
                  </a>
                </Link>
                <Link href="/documents">
                  <a>{t("other.documents.name")}</a>
                </Link>
                <Link href="/careers">
                  <a>{t("other.careers.name")}</a>
                </Link>
              </li>
            </>
          )}

          {generateSection(
            "footer.addressBilling",
            <>
              <li>Lptex, s.r.o.</li>
              <li>Južná trieda 119</li>
              <li>040 01 Košice</li>
              <li>Slovensko</li>
            </>
          )}
          {generateSection(
            "footer.addressOffice",
            <>
              <li>Lptex, s.r.o.</li>
              <li>Palackého 33</li>
              <li>040 01 Košice</li>
              <li>Slovensko</li>
            </>
          )}
        </div>
        <div className="text-center border-t-2 rounded md:text-lg c-container border-neutral-200 dark:border-neutral-700">
          © Copyright {new Date().getFullYear()} Lptex, s.r.o.
        </div>
      </footer>
    </>
  );
};

export default PageFooter;
