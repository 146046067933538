import LogoFull from "../LogoFull";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { XIcon, MenuAlt4Icon } from "@heroicons/react/solid";
import PopoverMenu from "@components/navigation/popoverMenu";
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";

const languages = [
  { name: "Slovensky", key: "sk" },
  { name: "English", key: "en" },
];

export default function PageNavigation() {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const router = useRouter();

  const updateSelected = (key = null) => {
    const language = key || router.locale;
    const languageIndex = languages.findIndex((lang) => lang.key === language);
    setSelected(languages[languageIndex]);
    if (key) {
      router.push("/", undefined, { locale: key });
    }
  };

  useEffect(() => {
    updateSelected();
  }, []);

  const navigationItems = [
    {
      name: "navigation.home",
      link: "/",
    },
    {
      name: "navigation.about",
      link: "/about",
    },
    {
      name: "navigation.projects",
      link: "/projects",
    },
    {
      name: "navigation.contact",
      link: "/contact",
    },
  ];

  return (
    <>
      <div className="bg-default">
        <div className="c-container !py-4 text-white">
          <div>
            <Listbox
              value={selected}
              onChange={(selected) => {
                updateSelected(selected.key);
              }}
            >
              <div className="relative z-10">
                <Listbox.Button className="relative pr-10 focus:outline-none">
                  <span className="block truncate">
                    {selected && selected.name}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="w-5 h-5" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded shadow dark:bg-neutral-800 max-h-60 focus:outline-none">
                    {languages.map((language) => (
                      <Listbox.Option
                        key={language.key}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 focus:outline-none ${
                            active
                              ? "bg-default bg-opacity-5 text-default"
                              : "text-black dark:text-white"
                          }`
                        }
                        value={language}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {language.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-default">
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
      </div>
      <div className="c-container">
        <nav className="flex items-center justify-between">
          <Link href="/">
            <a>
              <LogoFull priority loading="eager" />
            </a>
          </Link>
          <div className="hidden lg:block">
            {navigationItems.map((item) => {
              return (
                <Link href={item.link} key={navigationItems.indexOf(item)}>
                  <a className="px-3 text-xl transition hover:text-default">
                    {t(`${item.name}`)}
                  </a>
                </Link>
              );
            })}
            <PopoverMenu text={t("navigation.other")} />
          </div>
          <Link href="/rent">
            <a className="hidden uppercase lg:block button button-blue">
              {t("navigation.rental")}
            </a>
          </Link>
          <button onClick={() => setIsOpen(!isOpen)} className="lg:hidden">
            <MenuAlt4Icon className="w-8 h-8 text-black transition dark:text-white hover:text-default" />
          </button>
        </nav>
      </div>
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="fixed touch-none top-0 z-[999]"
      >
        <div className="relative inset-0 w-screen h-screen text-white bg-default lg:hidden">
          <button
            className="absolute right-4 top-8"
            onClick={() => setIsOpen(!isOpen)}
          >
            <XIcon className="w-8 h-8" />
          </button>
          <nav className="flex flex-col items-center justify-center h-full text-center">
            {navigationItems.map((item) => {
              return (
                <Link key={navigationItems.indexOf(item)} href={item.link}>
                  <a className="py-2 text-2xl group">{t(`${item.name}`)}</a>
                </Link>
              );
            })}
            <PopoverMenu text={t("navigation.other")} mobile={true} />
            <Link href="/rent">
              <a className="mt-8 uppercase button button-white">
                {t("navigation.rental")}
              </a>
            </Link>
          </nav>
        </div>
      </Transition>
    </>
  );
}
