import Image from "next/image";

export default function LogoFull({ props }) {
  return (
    <div className="flex items-center select-none">
      <Image
        src={"/logo.svg"}
        width={64}
        height={64}
        alt="Lptex logo"
        {...props}
      />
      <h3 className="ml-2 text-2xl font-semibold tracking-wider">Lptex</h3>
    </div>
  );
}
