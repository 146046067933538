import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useTranslation } from "next-i18next";
import Link from "next/link";

const items = [
  {
    name: "other.careers",
    href: "/careers",
  },
  {
    name: "other.documents",
    href: "/documents",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PopoverMenu({ text, mobile }) {
  const { t } = useTranslation("common");
  return (
    <Popover className="relative inline-block">
      {({ open }) => (
        <>
          <Popover.Button className="flex items-center justify-center transition focus:outline-none">
            <span
              className={classNames(
                mobile ? "py-2 pr-1 text-2xl group" : "pl-3 pr-1 text-xl"
              )}
            >
              {text}
            </span>
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-[999] w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
              <div className="overflow-hidden shadow-lg">
                <div className="relative grid gap-6 px-6 py-6 bg-white rounded dark:bg-neutral-800 sm:gap-8 sm:p-8">
                  {items.map((item) => (
                    <Link key={item.name} href={item.href}>
                      <a className="block p-3 -m-3 transition duration-150 ease-in-out rounded hover:bg-neutral-100 dark:hover:bg-neutral-700">
                        <p className="font-medium !text-neutral-900 dark:!text-white">
                          {t(`${item.name}.name`)}
                        </p>
                        <p className="mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                          {t(`${item.name}.description`)}
                        </p>
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
